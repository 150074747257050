import React from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import {
  getSelectedLineOfBusiness,
  isMultipleLineOfBusinessLoading,
  LinesOfBusinessData,
  selectMultipleLineOfBusiness,
} from '@/core/store/reducers/staticContent';
import { Loader } from '@/core/components/Loader';

import {
  LoadingItem,
  SectionWrapper,
  StyledContainer,
} from './HomepageWrapper.styles';
import { updateLobId } from '../Config/store/actions';
import { SectionItem } from './components/SectionItem';

export const HomepageWrapper = () => {
  const { data } = useSelector(selectMultipleLineOfBusiness);
  const loading = useSelector(isMultipleLineOfBusinessLoading);
  const dispatch = useDispatch();

  const setLob = (id: number) => dispatch(updateLobId(id));
  const setSelectedLineOfbusiness =
   (lob: LinesOfBusinessData) => dispatch(getSelectedLineOfBusiness(lob));

  if (loading) {
    return (
      <LoadingItem>
        <Loader isAlwaysVisible />
      </LoadingItem>
    );
  }

  return (
    <StyledContainer>
      <SectionWrapper>
        {data?.map(section => (
          <SectionItem
            setSelectedLineOfBusiness={() => setSelectedLineOfbusiness(section)}
            setLob={() => setLob(section.lobId)}
            title={section.name}
            accessList={section.accessList}
          />
        ))}
      </SectionWrapper>
    </StyledContainer>
  );
};
