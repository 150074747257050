import { PageHead } from '@/core/components/PageHead';

import { HomepageWrapper } from '@/features/Homepage';

const Homepage = () => (
  <>
    <PageHead title="Home" />
    <HomepageWrapper />
  </>
);

export default Homepage;
