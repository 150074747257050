import styled, { css } from 'styled-components';

import { H5 } from '@/core/components/typography';
import { Pill } from '@/core/components/Pill';
import { spacing } from '@/core/styles/mixins/spacing';

export const LineWrapper = styled.section`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  grid-auto-flow: row;
  width: 100%;
  max-width: 540px;
  height: ${spacing(7.75)};
  border: 1px solid ${({ theme }) => theme.colors.steel40};
  gap: ${spacing(1)};
  padding: ${spacing(2)};
  background: ${({ theme }) => theme.colors.white};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledPill = styled(Pill)`
  display: block;
  margin: 0 ${spacing(1)} 0 0;
`;

export const Wrapper = styled.section`
  display: flex;
  justify-content: start;
  align-items: center;

  width: 180px;
`;

export const LinkWrapper = styled.section<{ $isPointer?: boolean }>`
  display: flex;
  justify-content: end;
  align-items: center;

  ${({ $isPointer }) => $isPointer &&
    css`
      cursor: pointer;
    `}
`;

export const Number = styled(H5)`
  margin: 0 ${spacing(0.5)};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

export const Text = styled(H5)`
  margin-right: ${spacing(1.125)};
  color: ${({ theme }) => theme.colors.milimanBlue20};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

export const HeaderText = styled(H5)`
  display: flex;
  align-items: center;
  min-width: 180px;
  line-height: 22px;
`;
