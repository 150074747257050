import styled from 'styled-components';

import { spacing } from '@/core/styles/mixins/spacing';

export const StyledContainer = styled.div`
  padding: ${spacing(2)};
  padding-top: ${spacing(6)};
`;

export const SectionWrapper = styled.div`
  > :not(:first-child) {
    margin-top: ${spacing(3)};
  }
`;

export const LoadingItem = styled.section`
  width: 100%;
  height: 100%;
  padding: ${spacing(2)};
  background-color: ${({ theme }) => theme.colors.steel20};
`;
