/* eslint-disable react/jsx-one-expression-per-line */

import Head from 'next/head';

type HeadProps = {
  title: string;
}

export const PageHead = ({ title }: HeadProps) => (
  <Head>
    <title>
      {title} | Milliman Nodal
    </title>
  </Head>
);
