import styled from 'styled-components';

import { spacing } from '@/core/styles/mixins/spacing';
import { H5 } from '@/core/components/typography';
import { fontWeight } from '@/core/styles/mixins/typography';

export const Item = styled.section`
  width: 60%;
  background-color: ${({ theme }) => theme.colors.steel20};
`;

export const Title = styled(H5)`
  ${fontWeight('regular')};
  font-size: 20px;
  ${({ theme }) => theme.colors.charcoal}
`;

export const HeaderWrapper = styled.section`
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.section`
  width: 100%;
  height: 60px;
  gap: ${spacing(3)};
  margin: ${spacing(1)} 0 0 0;
`;
