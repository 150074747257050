import React from 'react';

import {
  ContentWrapper,
  HeaderWrapper, Item, Title,
} from './SectionItem.styles';
import { Line } from '../Line';

type accesListProps = {
  accessName: string;
  alertsCount: number;
  claimsCount: number;
}

type Props = {
  title: string;
  accessList: Array<accesListProps>;
  setLob: () => void;
  setSelectedLineOfBusiness: () => void;
}

export const SectionItem = ({
  title, accessList, setLob, setSelectedLineOfBusiness,
}: Props) => (
  <div>

    <Item>
      <HeaderWrapper>
        <Title>
          {title}
        </Title>
      </HeaderWrapper>

      {accessList.map(access => (
        <ContentWrapper>
          <Line
            setSelectedLineOfBusiness={setSelectedLineOfBusiness}
            setLob={setLob}
            featureName={access.accessName}
            alertsCount={access.alertsCount}
            claimsCount={access.claimsCount}
          />
        </ContentWrapper>
      ))}
    </Item>
  </div>

);

